import Vue from 'vue'

$(() => {
  if (!$('.js-image-file-list-input').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      data: {
        rules: $(id).data('rules'), // Validルール
        imageUrls: $(id).data('image-urls'),
        formValue: '',
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        this.refreshFormValue()

        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        selectedFiles(e) {
          for (let i = 0; i < e.target.files.length; i++) {
            this.selectedFile(e, i)
          }
        },
        selectedFile(e, index = 0) {
          e.preventDefault()
          let file = e.target.files[index]

          let that = this
          let formData = new FormData()

          const maxWidth = 1200;
          const maxHeight = 1200;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (readerEvent) => {
            const img = new Image()
            img.src = readerEvent.target.result
            img.onload = () => {
              let width = img.width
              let height = img.height

              let resizable = false
              if (width > maxWidth) {
                height = Math.round(height * maxWidth / width)
                width = maxWidth
                resizable = true
              }
              if (height > maxHeight) {
                width = Math.round(width * maxHeight / height)
                height = maxHeight
                resizable = true
              }

              if (resizable) {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                file = canvas.toDataURL(file.type)
              }

              formData.append('image[image_file]', file)
              $.ajax({
                url: '/api/v1/images',
                type: 'POST',
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
              }).done((data) => {
                that.appendUrl(data.image_file.url)
              })
            }
          }
        },
        appendUrl(url) {
          this.imageUrls.push(url)
          this.refreshFormValue()
          this.validForm()
        },
        deleteUrl(index) {
          this.imageUrls.splice(index, 1)
          this.refreshFormValue()
          this.validForm()
        },
        validForm() {
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          this.errors = []
          if (this.imageUrls.length == 0) {
            this.errors.push("少なくとも1件以上選択してください")
          }

          $(id).find('input').trigger('change')
        },
        movePrevUrl(index) {
          if (!this.imageUrls[index - 1]) {
            return
          }
          this.imageUrls.splice(index - 1, 2, this.imageUrls[index], this.imageUrls[index - 1])
          this.refreshFormValue()
        },
        moveNextUrl(index) {
          if (!this.imageUrls[index + 1]) {
            return
          }
          this.imageUrls.splice(index, 2, this.imageUrls[index + 1], this.imageUrls[index])
          this.refreshFormValue()
        },
        refreshFormValue() {
          for (let i = 0; i < this.imageUrls.length; i++) {
            if (!this.imageUrls[i]) {
              this.imageUrls.splice(i, 1)
            }
          }
          this.formValue = JSON.stringify(this.imageUrls)
        }
      },
    })
  }

  $('.js-image-file-list-input').each((i, element) => {
    const id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
